define('ember-resolver/utils/create', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var create = Object.create || Ember['default'].create;
  if (!(create && !create(null).hasOwnProperty)) {
    throw new Error("This browser does not support Object.create(null), please polyfil with es5-sham: http://git.io/yBU2rg");
  }

  exports['default'] = create;

});