define('ember-select-list/components/select-list', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({

    tagName: 'select',

    // possible passed-in values with their defaults:
    content: null,
    prompt: null,
    optionValuePath: null,
    optionLabelPath: null,
    required: false,
    title: null,
    action: Ember['default'].K, // action to fire on change
    tabindex: -1,
    disabled: null,

    attributeBindings: ['tabindex', 'required', 'title', 'disabled'],

    // shadow the passed-in `value` to avoid
    // leaking changes to it via a 2-way binding
    _selection: Ember['default'].computed.reads('value'),

    didInitAttrs: function didInitAttrs() {
      this._super.apply(this, arguments);
      if (!this.get('content')) {
        this.set('content', []);
      }
    },

    change: function change() {
      var selectEl = this.element;
      var selectedIndex = selectEl.selectedIndex;
      var content = this.get('content');

      // decrement index by 1 if we have a prompt
      var hasPrompt = !!this.get('prompt');
      var contentIndex = hasPrompt ? selectedIndex - 1 : selectedIndex;

      var selection = content[contentIndex];

      var value = this.attrs.optionValuePath ? Ember['default'].get(selection, this.get('optionValuePath')) : selection;

      // set the local, shadowed selection to avoid leaking
      // changes to `selection` out via 2-way binding
      this.set('_selection', value);

      var changeCallback = this.get('action');
      changeCallback(value);
    }
  });

});