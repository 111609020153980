define('ember-cli-data-export/components/export-selector-onselect', ['exports', 'ember', 'ember-cli-data-export/templates/components/export-selector-onselect'], function (exports, Ember, layout) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    layout: layout['default'],
    selectPrompt: 'Export File',

    actions: {
      triggerExport: function(selectedType) {
        this.sendAction('exportData', selectedType);
        this.$('#exportTypes').val('');
      }
    }
  });

});