define('ember-modal-dialog/components/tether-dialog', ['exports', 'ember', 'ember-modal-dialog/components/modal-dialog', 'ember-modal-dialog/templates/components/tether-dialog'], function (exports, Ember, ModalDialog, layout) {

  'use strict';

  var dasherize = Ember['default'].String.dasherize;
  var computed = Ember['default'].computed;
  var get = Ember['default'].get;

  var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  exports['default'] = ModalDialog['default'].extend({
    layout: layout['default'],

    targetAttachmentClass: computed('targetAttachment', function () {
      var targetAttachment = this.get('targetAttachment') || '';
      return 'ember-modal-dialog-target-attachment-' + dasherize(targetAttachment);
    }),

    targetAttachment: 'middle center',
    attachment: 'middle center',
    targetModifier: 'visible',
    hasOverlay: true,

    tetherClassPrefix: 'ember-tether',
    // offset - passed in
    // targetOffset - passed in

    makeOverlayClickableOnIOS: Ember['default'].on('didInsertElement', function () {
      if (isIOS && get(this, 'hasOverlay')) {
        Ember['default'].$('div[data-ember-modal-dialog-overlay]').css('cursor', 'pointer');
      }
    })

  });

});