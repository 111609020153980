define('ember-cli-data-export/components/export-selector', ['exports', 'ember', 'ember-cli-data-export/templates/components/export-selector'], function (exports, Ember, layout) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    layout: layout['default'],
    types: Ember['default'].computed(function() {
      return [this.get('selectPrompt'), 'Excel', 'CSV']
    }),
    selectedType : null,
    actionText: 'Export',
    selectPrompt: 'Export File',

    actions: {
      triggerExport: function() {
        this.sendAction('exportData', this.get('selectedType'));
      }
    }
  });

});