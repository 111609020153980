define('ember-resolver/utils/make-dictionary', ['exports', 'ember-resolver/utils/create'], function (exports, create) {

  'use strict';



  exports['default'] = makeDictionary;
  function makeDictionary() {
    var cache = create['default'](null);
    cache['_dict'] = null;
    delete cache['_dict'];
    return cache;
  }

});