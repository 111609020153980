define('ember-i18n/utils/add-translations', ['exports', 'ember'], function (exports, Ember) {

  'use strict';



  exports['default'] = addTranslations;
  function addTranslations(locale, newTranslations, owner) {
    var key = "locale:" + locale + "/translations";
    var existingTranslations = owner._lookupFactory(key);

    if (existingTranslations == null) {
      existingTranslations = {};
      owner.register(key, existingTranslations);
    }

    Ember['default'].merge(existingTranslations, newTranslations);
  }

});