define('ember-tooltips/components/popover-on-component', ['exports', 'ember-tooltips/components/popover-on-element'], function (exports, PopoverOnElementComponent) {

  'use strict';

  exports['default'] = PopoverOnElementComponent['default'].extend({
    tetherComponentName: 'tether-popover-on-component',

    _shouldTargetGrandparentView: true
  });

});